export const FLOCK_LANDING_URL = process.env.GATSBY_FLOCK_LANDING_URL
export const FLOCK_INVESTOR_SIMULATOR_URL =
  process.env.GATSBY_FLOCK_INVESTOR_SIMULATOR_URL
export const FLOCK_PARTNER_SIMULATOR_URL =
  process.env.GATSBY_FLOCK_PARTNER_SIMULATOR_URL
export const HOME_OPS_BASE_URL = '/app/home-ops'

export const HOME_OPS_URL = `${HOME_OPS_BASE_URL}/lead-management`
export const HOME_OPS_CUSTOMER_URL = `${HOME_OPS_BASE_URL}/customer-management`
export const HOME_OPS_TASKS = `${HOME_OPS_BASE_URL}/tasks`
export const HOME_OPS_CREATE_LEAD = `${HOME_OPS_BASE_URL}/create-lead-old`
export const HOME_OPS_VALUATION_URL = `${HOME_OPS_BASE_URL}/valuation-tool`
export const SALES_BASE_URL = '/app/sales'
export const SALES_TASKS = `${SALES_BASE_URL}/inbound-tasks`
export const SALES_OUTBOUND_TASKS = `${SALES_BASE_URL}/outbound-tasks`
export const SALES_LEAD_MANAGEMENT_URL = `${SALES_BASE_URL}/lead-management`
export const SALES_CUSTOMER_MANAGEMENT_URL = `${SALES_BASE_URL}/customer-management`
export const SALES_LEAD_DOCUMENTS_CENTER_URL = `${SALES_BASE_URL}/lead-document-center`
export const BROKER_MANAGEMENT_URL = `${SALES_BASE_URL}/broker-management`
export const EXCHANGE_URL = '/app/exchange'
export const ORDER_ONBOARDING_URL = `${EXCHANGE_URL}/orders`
export const INFRA_URL = '/app/infra'
export const NEW_CONTRIBUTIONS_URL = `${EXCHANGE_URL}/new-contributions`
export const NEW_ORDER_CREATION_URL = `${EXCHANGE_URL}/new_order`
export const FUND_MANAGEMENT_URL = '/app/fund-management'
export const INVESTOR_MANAGEMENT_URL = `${FUND_MANAGEMENT_URL}/investor-management`
export const DISTRIBUTIONS_URL = `${FUND_MANAGEMENT_URL}/distributions`
export const REPORTS_URL = `${FUND_MANAGEMENT_URL}/reports`

export const TRUSTPILOT_URL = 'https://www.trustpilot.com/review/flockhomes.com'

export const Q2_2023_INVESTOR_CALL = 'https://vimeo.com/850676028/814c557453'
export const Q3_2023_INVESTOR_CALL = 'https://vimeo.com/882234151/79de8d80c1'
export const Q4_2023_INVESTOR_CALL = 'https://vimeo.com/908392542/0c01ca938c'
export const Q1_2024_INVESTOR_CALL = 'https://vimeo.com/938798610/513b2d9c7d'
export const Q2_2024_INVESTOR_CALL = 'https://vimeo.com/989758072/42b0fdfe4f'
export const Q3_2024_INVESTOR_CALL = 'https://vimeo.com/1023064774/db05656dd9'
export const Q4_2024_INVESTOR_CALL = 'https://vimeo.com/1051261605/3dbf890658'

export const FLOCK_WEBINAR_URL =
  'https://flockhomes.docsend.com/view/z34pnxaccteaqj8p'

export const DATA_LAKE_BASE_URL = '/app/data-lake'
export const DATA_LAKE_SEARCH_URL = `${DATA_LAKE_BASE_URL}/search`
export const DATA_LAKE_REPORTING_URL = `${DATA_LAKE_BASE_URL}/reporting`
export const DATA_LAKE_UPDATES_URL = `${DATA_LAKE_BASE_URL}/updates`
export const OCCUPANCY_STATUS_URL = `${DATA_LAKE_BASE_URL}/occupancy-status`

export const ADDRESS_SEARCH_URL = '/app/address_search'
export const { GATSBY_ENV } = process.env
export const GOOGLE_MAPS_API_KEY = process.env.GATSBY_GOOGLE_MAPS_API_KEY

export const DOCUMENTS_TYPE_LEGAL_ENTITY = 'legal_entity'
export const DOCUMENTS_TYPE_INVESTOR_ACCOUNT = 'investor_account'

export const SAM_OPERATOR_UUID = '778bb9f9-464b-4766-9d87-9da403ae05ac'
export const JACOB_OPERATOR_UUID = 'f9c0e463-48a3-4b89-a7ce-437cd8cb8ad2'
export const RAYMOND_OPERATOR_UUID = '7df108e0-19d9-48cf-98ac-395316d902ca'
export const ARI_OPERATOR_UUID = 'a7014674-a842-437e-95da-442c430697e6'
export const COLIN_OPERATOR_UUID = '55a91781-9619-4f4e-8804-05aa76c3d71c'
export const KYLE_OPERARTOR_UUID = 'f90983d9-a363-4348-953e-df773668bf57'
export const JENNETTE_OPERATOR_UUID = '2e374430-a261-41c0-a663-98cf9b5efbe3'
export const JACK_OPERATOR_UUID = '2214ea6a-b61a-4cd7-ba22-c9caab2c9849'
export const JEREMY_OPERATOR_UUID = '7b882957-7563-48d1-99bd-b0e30f684a55'
export const CHAD_OPERATOR_UUID = 'd0589bf7-246b-4440-945c-52c31787beb1'
export const ELLIOT_OPERATOR_UUID = 'c28f628a-966d-48e4-9ef1-ddfc799a578d'

export const CUSTOMER_TESTIMONIAL_BRODBECK =
  'https://flockhomes.docsend.com/view/ga4z8ie4ex5rwp9a'
export const CUSTOMER_TESTIMONIAL_GHOENS =
  'https://flockhomes.docsend.com/view/xmg4vhba7kivjq59'
export const CUSTOMER_TESTIMONIAL_KREIS =
  'https://flockhomes.docsend.com/view/6si78ni52cxw4kq3'
export const CUSTOMER_TESTIMONIAL_WIZELMAN =
  'https://flockhomes.docsend.com/view/grxc65yjcjy7ey5p'
export const CUSTOMER_TESTIMONIAL_COMPILATION =
  'https://flockhomes.docsend.com/view/syxfqgxe9wcjneb8'

export const ACCOUNT_DOCUMENTS_URL = '/app/account-documents'
export const ADDRESS_CREATION_URL = '/app/create-addresses'
export const SOW_HOME = '/app/sow'

export const VALUATION_PRINT_COLLATERAL_URL = `${HOME_OPS_BASE_URL}/print-collateral`

export const SALESFORCE_URL = process.env.GATSBY_SALESFORCE_URL

export const allStates = [
  'AK',
  'AL',
  'AR',
  'AS',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'GU',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MP',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UM',
  'UT',
  'VA',
  'VI',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
]

export const allStatesLongForm = [
  'Alabama',
  'Alaska',
  'American Samoa',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Federated States of Micronesia',
  'Florida',
  'Georgia',
  'Guam',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Marshall Islands',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Northern Mariana Islands',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Palau',
  'Pennsylvania',
  'Puerto Rico',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virgin Island',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
]
